<template>
  <section class="main">
    <div class="container">
      <div class="left">
        <!-- <el-button class="btn" @click="backLast()" size="small">返回</el-button> -->
        <div class="box" @click="newCreated()">
          <!-- <div>
            <img size="small" src="../../assets/home/small-logo.png" alt="" />
            <span>平行方舟大模型</span>
          </div>
          <div class="new" @click="newCreated()">+新建</div> -->
          <img src="https://oss.zijingshuke.com/1719910718961.png" alt="" />
          <span>新建对话</span>
        </div>
        <!-- 历史记录 -->
        <div class="hisarr">
          <!-- <div @click="addhistory(item)" class="text" v-for="(item,i) in historyArr" :key="i">{{ item.info }}</div> -->
        </div>
      </div>
      <div class="right">
        <!-- 对话框 -->
        <div v-show="isShow" class="content" ref="chatContainer">
          <div class="chat-messages">
            <div ref="dialog" class="chat" v-for="(message, index) in messages" :key="index"
              style="white-space: pre-wrap">
              <div v-show="message.isUser" class="message" :class="{ 'user-message': message.isUser }">
                <div>
                  <img style="width: 35px" src="../../assets/home/avatar.png" alt="" />
                </div>
                <div class="input1box">
                  <!-- <el-input
                    type="textarea"
                    :autosize="{ minRows: 1, maxRows: 100 }"
                    size="mini"
                    placeholder="请输入内容"
                    v-model="message.text"
                    class="input1"
                    readonly
                  >
                  </el-input> -->
                  <span class="highlight"> {{ message.text }}</span>
                </div>
              </div>

              <div v-show="!message.isUser" class="kefu">
                <div class="replay">
                  <img v-show="!message.loading" class="kefuAvatar" src="../../assets/home/small-logo.png" alt="" />
                  <i v-show="message.loading" style="font-size: 2rem; color: blue; margin-left: 25px"
                    class="el-icon-loading"></i>
                  <div class="replay-box" style="width: 90%" v-show="!message.loading">

                    <el-input v-show="index == messages.length - 1 && !message.loading" type="textarea"
                      :autosize="{ minRows: 2, maxRows: 400 }" placeholder="抱歉，请求异常，您的问题暂时无法回答" v-model="flowData" class="input2">
                    </el-input>
                    <el-input v-show="index < messages.length - 1 && !message.loading" type="textarea"
                      :autosize="{ minRows: 2, maxRows: 400 }" placeholder="抱歉，请求异常，您的问题暂时无法回答" v-model="message.replay"
                      class="input2">
                    </el-input>
                    <div class="bottom">
                      <img v-show="!message.isGood && !message.loading" @click="isGoodClick(index)"
                        src="https://oss.zijingshuke.com/1719989713217.png" alt="" />
                      <img v-show="message.isGood && !message.loading" @click="isGoodClick(index)"
                        src="https://oss.zijingshuke.com/1719990847913.png" alt="" />
                      <img v-show="message.isBad && !message.loading" @click="isBadClick(index)"
                        src="https://oss.zijingshuke.com/1719990746538.png" alt="" />
                      <img v-show="!message.isBad && !message.loading" @click="isBadClick(index)"
                        src="https://oss.zijingshuke.com/1719990761916.png" alt="" />
                      <img v-show="index < messages.length - 1 && !message.loading" @click="copyText(message.replay)"
                        src="https://oss.zijingshuke.com/1719990826543.png" alt="" />
                      <img v-show="index == messages.length - 1 && !message.loading" @click="copyText(flowData)"
                        src="https://oss.zijingshuke.com/1719990826543.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 提示 -->
        <div v-show="!isShow" class="tips">
          <div class="image">
            <img src="../../assets/home/small-logo.png" alt="" />
          </div>
          <div class="tip">
            <p class="span" @mouseup="handleMouseSelect">
              你好，我是平行方舟通用知识问答大模型
            </p>
            <p class="p">
              欢迎向我提问,可以帮你高效完成AI问答，提高你的工作学习效率。
            </p>
          </div>
        </div>
        <div class="askInput">
          <textarea class="textarea" v-model="newMessage" @keyup.enter="sendMessage" placeholder="很高兴为您服务，请描述您的问题" />
          <img class="send" @click="sendMessage" src="../../assets/drive/send.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 添加文献图标 -->
    <!-- <div class="addfile">
      <el-tooltip
        class="item"
        effect="dark"
        content="添加文献"
        placement="top-start"
      >
        <img src="https://oss.zijingshuke.com/1720572848959.png" alt="" />
      </el-tooltip>
    </div> -->
    <!-- 添加文献模块 -->
    <div class="addfile-box" v-show="isAddFile">
      <div class="addfile-section">
        <p class="font">添加文献</p>
      </div>
    </div>
  </section>
</template>

<script>
// 在页面加载完成后执行
window.onload = function () {
  // 将滚动条置于顶部
  setTimeout(function () {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, 100);
};
export default {
  data() {
    return {
      // 是否显示添加文献模块
      isAddFile: false,
      // 是否显示对话框
      isShow: false,
      // 当前时间
      time: "",
      //  问答数组
      messages: [],
      // 问题
      newMessage: "",
      //回复
      replay: "",
      // 历史记录
      historyArr: [
        { info: "RAG是什么", replay: "gggg" },
        { info: "一年有几个月", replay: "12个" },
      ],
      // 回复蹦出来的数组
      concatenatedReplay: [],
      // 流式数据
      flowData: "",
    };
  },
  methods: {

    // 复制文本
    copyText(text) {
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      this.$message.success("复制成功");
    },
    // 返回上一页
    backLast() {
      this.$router.push("/map");
    },
    // 发送问题
    sendMessage() {
      this.isShow = true;
      // console.log(this.isShow);
      if (this.newMessage.trim() !== "") {
        this.messages.push({
          text: this.newMessage,
          isUser: true,
          time: this.time,
          loading: true,
        });
        this.$nextTick(() => {
          this.scrollToBottom();
        });
        this.fetchData();
        this.newMessage = "";
      }
    },
    // 请求问题答案
    request() {
      var param = {
        query: this.newMessage,
      };
      this.messages.push({
        replay: "正在加载中~",
        isUser: false,
        time: this.time,
        loading: true,
        isGood: false,
        isBad: false,
      });
      this.$post("/agent/ai/chat/", param, { withCredentials: true })
        .then((res) => {
          // 主要参数
          console.log(res);
          this.messages.pop();
          this.replay = res.llm_response;
          this.$nextTick(() => {
            this.scrollToBottom();
          });
          // 在实际应用中，你可以在这里发送消息给客服
          this.messages.push({
            replay: this.replay,
            isUser: false,
            time: this.time,
            loading: false,
            isGood: false,
            isBad: false,
          });
          this.concatenateReplay();
          this.$nextTick(() => {
            this.scrollToBottom();
          });
        })
        .catch(() => {
          console.log("异常");
          this.messages.pop();
          this.messages.push({
            replay: "抱歉，请求异常，您的问题暂时无法回答",
            isUser: false,
            time: this.time,
            loading: false,
            isGood: false,
            isBad: false,
          });
          this.$nextTick(() => {
            this.scrollToBottom();
          });
        });
    },
    //  流式输出数据
    async fetchData() {
      // let receivedText = '';
      this.messages.push({
        replay: "正在加载中~",
        isUser: false,
        time: this.time,
        loading: true,
        isGood: false,
        isBad: false,
      });
      this.flowData = "";
      try {
        const response = await fetch(
          "http://bxai.parallelark.com:8000/agent/ai/chat/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ query: this.newMessage.trim() }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const reader = response.body.getReader();

        while (true) {
          const { done, value } = await reader.read();

          if (done) break;

          const textChunk = new TextDecoder("utf-8").decode(value);
          this.flowData += textChunk;
        
          if (this.flowData.includes('start')) {
            this.messages.pop()
            this.messages.push({
              replay: "正在加载中~",
              isUser: false,
              time: this.time,
              loading: false,
              isGood: false,
              isBad: false,
            });
            this.flowData = this.flowData.replace('start', '')
          }
        
          this.$nextTick(() => {
            this.scrollToBottom();
          });
        }

        // console.log(this.flowData);
        this.messages.push({
          replay: this.flowData,
          isUser: false,
          time: this.time,
          loading: false,
          isGood: false,
          isBad: false,
        });
        this.messages.splice(this.messages.length - 2, 1);
      } catch (error) {
        this.messages.pop();
        this.flowData= '抱歉，请求异常，您的问题暂时无法回答'
        this.messages.push({
          replay: '抱歉，请求异常，您的问题暂时无法回答',
          isUser: false,
          time: this.time,
          loading: false,
          isGood: false,
          isBad: false,
        });
        console.error("Error fetching data:", error);
      }
    },
    scrollToBottom() {
      // 在初始化时将滚动条滚动到底部
      this.$nextTick(() => {
        let chatContainer = this.$refs.chatContainer;

        chatContainer.scrollTop = chatContainer.scrollHeight;

      });
    },
    // 添加历史记录
    addhistory(item) {
      this.messages.push({
        text: item.info,
        isUser: true,
        time: this.time,
        loading: true,
        isGood: false,
        isBad: false,
      });
      this.$nextTick(() => {
        this.scrollToBottom();
      });
      setTimeout(() => {
        this.messages.push({
          replay: item.replay,
          isUser: false,
          time: this.time,
          loading: false,
        });
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      }, 100);
    },
    // 点赞
    isGoodClick(i) {
      this.messages[i].isGood = !this.messages[i].isGood;
      this.messages[i].isBad = false;
    },
    // 踩
    isBadClick(i) {
      this.messages[i].isBad = !this.messages[i].isBad;
      this.messages[i].isGood = false;
    },
    // 新建
    newCreated() {
      this.isShow = false;
      this.messages = [];
    },
    handleMouseSelect() {
      let text = window.getSelection().toString();
      console.log(text);
    },
    // 返回按钮
    ToMap() {
      this.$router.push("/map");
    },
  },
  beforeDestroy() {
    // 清除定时器
    if (this.setTime) {
      clearInterval(this.setTime);
    }
  },
};
</script>

<style lang="scss">
.input1box {
  width: 90%;
  margin: 0 10px;
}

.input1 {
  // margin: 0 10px;
  // height: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;

  .el-textarea__inner {
    background: #ccc !important;
    color: #000;
    font-size: 18px;
    border-radius: 10px;
    padding-top: 18px;
    box-sizing: border-box;
  }
}

.input2 {
  .el-textarea__inner {
    border: none;
    outline: none;
  }
}
</style>
<style scoped lang="scss">
.main {
  position: relative;
}

// 添加文献图标
.addfile {
  position: absolute;
  right: 20px;
  top: 20px;

  img {
    width: 35px;
  }
}

// 添加文献模块
.addfile-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  .addfile-section {
    width: 60%;
    height: 500px;
    background: #fff;
    margin: 80px auto 0;
    border-radius: 20px;
    padding: 10px;
    box-sizing: border-box;

    .font {
      width: 100%;
      text-align: center;
    }
  }
}

.container {
  display: flex;
  overflow-x: hidden;

  .left {
    background: #e9e9f4;
    width: 22%;
    height: calc(100vh - 115px);
    height: 100vh;
    margin-left:0px ;
    .btn {
      margin: 20px 0;
    }

    .box {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 60px 20px 20px 20px;
      background: linear-gradient(to right, #dd88ff, #6d5ffd);
      padding: 10px 5px;
      box-sizing: border-box;
      // border: 2px solid transparent;
      border-radius: 25px;

      // overflow: hidden;
      img {
        vertical-align: middle;
        width: 25px;
        margin-right: 10px;
      }

      span {
        vertical-align: middle;
        color: #fff;
        cursor: pointer;
        font-size: 19px;
      }

      .new {
        color: #4955f5;
        font-size: 20px;
        cursor: pointer;
      }
    }

    // 历史记录
    .hisarr {
      .text {
        margin: 20px 25px;
        cursor: pointer;
        user-select: text;
      }
    }
  }

  .right {
    background: #ebeef7;
    width: 80%;
    height: calc(100vh - 115px);
    height: 100vh;
    position: relative;
    // margin-top: 20px;
    .tips {
      width: 80%;
      margin: 20px auto;
      height: 400px;
      background: #e9e9f4;
      padding: 20px;
      border-radius: 20px;
      box-shadow: inset 0 0 0 2px rgb(201, 137, 187);

      .image {
        width: 100%;
        text-align: center;
        margin-top: 100px;
      }

      .span {
        width: 100%;
        text-align: center;
        margin: 10px auto;
        font-size: 18px;
        font-weight: 600;
        user-select: auto;
        z-index: 99;
      }

      .p {
        width: 100%;
        text-align: center;
        margin: 10px auto;
        user-select: auto;
      }
    }

    .content {
      margin: 20px 0 20px 20px;
      width: 100%;
      height: 76vh;
      overflow-y: scroll;
    }

    .chat-messages {
      height: 100%;
      width: 90%;
      margin: 0 5%;

      .message {
        border-radius: 5px;
        box-sizing: border-box;
        white-space: pre-wrap;
        display: flex;

        .highlightInput {
          border: none;
          outline: none;
          margin: 0 10px;
          padding: 5px;
          background: #ccc;
          font-size: 16px;
          resize: none;
          /* 禁止用户手动调整大小 */
          // overflow: hidden; /* 隐藏溢出的内容 */
          width: 100%;
          /* 设置初始宽度 */
          height: auto;
          border: none;
          /* 去除边框 */
        }

        span {
          vertical-align: middle;
          margin: 0 10px;
          user-select: text;
        }

        img {
          vertical-align: middle;
          width: 35px;
        }
      }

      .askTime {
        color: #333333;
      }

      .user-message {
        color: #fff;
        white-space: wrap;
        margin: 10px 0;

        span {
          background-color: #ccc;
          color: #000;
          padding: 5px;
          border-radius: 5px;
          user-select: text;
          box-sizing: border-box;
          display: inline-block;
          vertical-align: middle;
        }
      }

      .agent-message {
        background-color: #eee;
        float: left;
        clear: both;
      }

      // 客服回复
      .kefu {
        color: #333333;

        img {
          vertical-align: middle;
          width: 38px;
          height: 38px;
          border-radius: 50%;
        }

        span {
          vertical-align: middle;
          user-select: text;
        }

        .replay {
          margin: 20px 0;
          display: flex;

          .replay-box {
            background: #fff;
            padding: 10px 15px 10px 10px;
            margin: 0 10px;
            border-radius: 10px;
            box-sizing: border-box;

            .bottom {
              width: 100%;
              text-align: right;

              img {
                width: 25px;
                height: 25px;
              }
            }
          }

          span {
            border-radius: 3px;

            // margin: 10px 0;
            display: inline-block;
            user-select: text;
          }
        }
      }
    }

    .askInput {
      width: 80%;
      min-height: 120px;
      border: 2px solid rgb(137, 157, 201);
      // box-shadow:  inset 0 0 0 2px rgb(201, 137, 187);
      border-radius: 5px;
      position: absolute;
      bottom: 30px;
      left: 10%;
      background: #fff;

      .textarea {
        width: 100%;
        min-height: 80px;
        font-size: 16px;
        padding: 5px;
        box-sizing: border-box;
      }

      textarea {
        border: none;
        resize: none;
      }

      textarea:focus,
      textarea:active {
        border: none;
        outline: none;
      }

      textarea::-webkit-input-placeholder {
        color: #ccc;
        font-size: 16px;
        padding: 5px;
        box-sizing: border-box;
      }

      .send {
        position: absolute;
        width: 30px;
        right: 3px;
        bottom: 2px;
      }
    }
  }
}
</style>